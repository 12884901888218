import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import Loading from "../error/Loading";
import { useSelector, useDispatch } from "react-redux";
import "./Progressbar.css";
import logo from "../../assets/images/vendor-portal.png";
import { Document, Page } from "@react-pdf-viewer/core";
import {
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardMedia,
  CardActions,
} from "@mui/material";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import axios from "axios";
import { InvoiceAPI } from "../../services/dbApi";
import { ToWords } from "to-words";
const toWords = new ToWords();

const stateMasterCode = {
  1: "JAMMU AND KASMIR",
  2: "HIMACHAL PRADESH",
  3: "PUNJAB",
  4: "CHANDIGARH",
  5: "UTTARAKHAND",
  6: "HARYANA",
  7: "DELHI",
  8: "RAJASTHAN",
  9: "UTTAR PRADESH",
  10: "BIHAR",
  11: "SIKKIM",
  12: "ARUNACHAL PRADESH",
  13: "NAGALAND",
  14: "MANIPUR",
  15: "MIZORAM",
  16: "TRIPURA",
  17: "MEGHALAYA",
  18: "ASSAM",
  19: "WEST BENGAL",
  20: "JHARKHAND",
  21: "ORISSA",
  22: "CHHATTISGARH",
  23: "MADHYA PRADESH",
  24: "GUJARAT",
  26: "DADAR AND NAGAR HAVELI",
  27: "MAHARASTRA",
  29: "KARNATAKA",
  30: "GOA",
  31: "LAKSHADWEEP",
  32: "KERALA",
  33: "TAMIL NADU",
  34: "PONDICHERRY",
  35: "ANDAMAN AND NICOBAR",
  36: "TELENGANA",
  37: "ANDHRA PRADESH",
  38: "LADAKH",
  97: "OTHER TERRITORY",
  96: "OTHER COUNTRIES",
};
const InvoiceForm = ({ selectedInvoice, setCurrentTab, setInvoiceSuccess }) => {
  const location = useLocation(); //akshay
  // const { dataItem, userdetails } = location.state;  //akshay

  //axios.defaults.withCredentials = true;
  const userdetails = useSelector((state) => state.userdetails);
  const dispatch = useDispatch();

  const userID = userdetails?.userData?.userID;

  const checkUserID = process.env.REACT_APP_CHECK_USER_ID;

  /**- Error Handling -**/

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadedInvoiceFile, setUploadedInvoiceFile] = useState(null); // to be tested
  const [uploadedSupportFile, setUploadedSupportFile] = useState(null); //

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      basic_invoice_value: "0",
      discount: "0",
      commodity_charges: "0",
      taxable_value_of_invoice: "0",
      assessable_value: "0",
      customs_duty_others: "0",
      cgst: "0",
      sgst: "0",
      igst: "0",
      utgst: "0",
      cess: "0",
      total_tax_amount: "0",
      other_charges: "0",
      total_invoice_amount: "0",
      round_off_value: "0",
      ...selectedInvoice,
    },
  });

  /**- Business States -**/
  const [businessCodeList, setBusinessCodeList] = useState();
  const [businessAllGst, setBusinessAllGst] = useState([
    `${selectedInvoice?.business_gstno}`,
  ]);

  /**- Supplier States -**/
  const [suppliersData, setSuppliersData] = useState([]);
  const [supplierAllGst, setSupplierAllGst] = useState([
    `${selectedInvoice?.partner_gstno}`,
  ]);

  /**- PO States -**/
  const [POName, setPOName] = useState([]);
  const [selectedPO, setSelectedPO] = useState();
  const [posDetails, setPosDetails] = useState([]);
  const [showPoType, setPOType] = useState(0);
  const [disableGST, setdisableGST] = useState(1);
  const [document_date, setDocumentDate] = useState();
  const [isShowInput, setIsShowInput] = useState(0);
  const [isShowGstInput, setIsShowGstInput] = useState(0);
  const [showMSME, setShowMSME] = useState(0);
  const [disableMSMENo, setdisableMSMENo] = useState(0);
  const [URDcheck, setURDcheck] = useState(null);

  React.useEffect(() => {
    if (selectedInvoice?.basic_invoice_value) {
      handleInvoiceValues({
        target: {
          name: "basic_invoice_value",
          value: selectedInvoice?.basic_invoice_value,
        },
      });
    }
    selectedInvoice?.document_date &&
      setDocumentDate(
        moment(selectedInvoice?.document_date).format("YYYY-MM-DD")
      );
    // setInvoiceSuccess({
    //   isInvoiceUploaded: false,
    //   isDraft: false,
    // });
    setInvoiceSuccess({
      isInvoiceUploaded: "test",
      isDraft: "test",
    });
  }, []);

  /**- Invoice States -**/
  const [invoiceList, setInvoiceList] = useState();
  const [isImport, setIsImport] = useState("No");

  const [InvoiceStatus, setInvoiceStatus] = useState("");

  /**- Initial API Calls -**/
  const getAllBusiness = async (userId) => {
    try {
      setError(false);
      const res = await InvoiceAPI.businessAll(userId);
      setBusinessCodeList(res?.data?.result);
      // console.log(res);
      // setTimeout(() => {
      //   setLoading(false);
      // }, 2000);
      setLoading(false);
    } catch (error) {
      // console.log(error);
      if (error.response) {
        setErrorMessage(error?.response?.data?.message);
      } else {
        setErrorMessage(
          "Internal Server Isssue,Apologies for the inconvenience caused!"
        );
      }
      setError(true);
      setLoading(false);
    }
  };
  const Invoices = async (userId) => {
    try {
      setLoading(true);
      setError(false);
      const res = await InvoiceAPI.Invoices(userId, getValues("business_code"));
      setInvoiceList(res?.data?.result);
      // setTimeout(() => {
      //   setLoading(false);
      // }, 3000);
      setLoading(false);
      // setLoading(false);
    } catch (error) {
      if (error.response) {
        setErrorMessage(error?.response?.data?.message);
      } else {
        setErrorMessage(
          "Internal Server Isssue,Apologies for the inconvenience caused!"
        );
      }
      setError(true);
      setLoading(false);
      // setTimeout(() => {
      //   setLoading(false);
      // }, 3000);
    }
  };

  useEffect(() => {
    // getAllBusiness(userID[0]);
    // Invoices(userID[0]);
    getAllBusiness(userID);
    Invoices(userID);
  }, [userID]);

  /**- Business Methods Begins-**/
  const changeBusiness = (e) => {
    if (e.target.value === "") {
      reset();
      return;
    }
    setValue("business_code", e.target.value);
    InvoiceAPI.business(e.target.value)
      .then((res) => {
        if (res.data.result.length !== 0) {
          const fetchBusinessData = res?.data?.result;
          setValue("business_name", fetchBusinessData[0]?.company_name);
          setValue("business_panno", fetchBusinessData[0]?.pan_no);
          const temGSTs = [];
          fetchBusinessData?.map((b, i) => temGSTs.push(b?.gst_no));
          setBusinessAllGst(temGSTs);
        }
      })
      .catch((err) => {
        setError(true);
        //console.log(err);
      });
    /**- SUPPLIER DATA -**/
    // userID[0];

    InvoiceAPI.getSupplier(userID, e.target.value)
      .then((res) => {
        if (res.data.result.length !== 0) {
          const fetchSupplierData = res?.data?.result;
          const URDcheck = fetchSupplierData[0]?.gst_no;
          setURDcheck(URDcheck);
          setSuppliersData(fetchSupplierData);
          const temGSTs = [];
          fetchSupplierData?.map((b) => temGSTs.push(b?.gst_no));
          setSupplierAllGst(temGSTs);
          //  userID[1]
          //console.log(fetchSupplierData);
          setValue("partner_name", userdetails.userData.username);
          setValue("partner_panno", fetchSupplierData[0].pan_no);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const changebusinessGstNo = (e) => {
    if (e.target.value === "") {
      setValue("business_billing_address", "");
      setValue("business_email", "");
      setValue("business_state_name", "");
      return;
    }
    InvoiceAPI.businessGST(e.target.value).then((res) => {
      setValue("business_billing_address", res?.data?.result[0]?.full_address);
      setValue("business_email", res?.data?.result[0]?.email);
      setValue("business_state_name", res?.data?.result[0]?.state_name);
    });
  };
  /**- Business Methods Ends -**/
  /**- PO-Suppliers Methods Begins -**/
  const handlePOType = (e) => {
    if (e.target.value === "") {
      setPOType(-1);
      setdisableGST(1);
      setValue("po_type", e.target.value);
      setValue("remaining_amount", "");
      setValue("po_date", "");
      setValue("selected_purchase_order", "");
      setValue("selected_purchase_order", "");
      setValue("partner_address", "");
      setValue("partner_state_name", "");
      setValue("partner_email", "");
      setValue("partner_gst_status", "");
      setValue("partner_gstno", "");
      return;
    }
    if (e.target.value === "Non-PO") {
      setdisableGST(0);
      setPOType(-1);
      setValue("po_type", e.target.value);
      setValue("remaining_amount", "");
      setValue("po_date", "");
      setValue("selected_purchase_order", "");
      setValue("remaining_amount", "");
      setValue("partner_state_name", "");
      setValue("partner_email", "");
      setValue("partner_gst_status", "");
      setValue("partner_gstno", "");
      return;
    }

    //testing for EL
    if (e.target.value === "Engagement-Letter") {
      setdisableGST(0);
      setPOType(2);
      setValue("po_type", e.target.value);
      setValue("remaining_amount", "");
      setValue("po_date", "");
      setValue("selected_purchase_order", "");
      setValue("remaining_amount", "");
      setValue("partner_state_name", "");
      setValue("partner_email", "");
      setValue("partner_gst_status", "");
      setValue("partner_gstno", "");
      return;
    }
    //

    //Non-PO amount under 25000
    if (e.target.value === "Misc-Spent") {
      setdisableGST(0);
      setPOType(3);
      setValue("po_type", e.target.value);
      setValue("remaining_amount", "");
      setValue("po_date", "");
      setValue("selected_purchase_order", "");
      setValue("remaining_amount", "");
      setValue("partner_state_name", "");
      setValue("partner_email", "");
      setValue("partner_gst_status", "");
      setValue("partner_panno", "");
      setValue("partner_gstno", "");
      return;
    }
    //
    setPOType(0);
    setValue("po_type", e.target.value);
    if (e.target.value === "PO") {
      setdisableGST(1);
      setPOType(1);
      setValue("po_type", e.target.value);
    }
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await InvoiceAPI.getPODetails(
          // userID[0],
          userID,
          getValues("business_code")
        );
        if (response?.data?.result?.length !== 0) {
          const fetchPOData = response?.data?.result;
          setPosDetails(fetchPOData);
          const temGSTs = [];
          fetchPOData?.map((b) => temGSTs.push(b?.purchase_order));
          setPOName(temGSTs);
        }
        // setTimeout(() => {
        //   setLoading(true);
        // }, 2000);
        // setErrorMessage(response);
        setLoading(false);
      } catch (error) {
        if (error.response) {
          setErrorMessage(error?.response?.data?.message);
        } else {
          setErrorMessage(
            "Internal Server Isssue,Apologies for the inconvenience caused!"
          );
        }
        setError(true);
        setLoading(false);
      }
    })();
  };
  const handleOpenPO = (e) => {
    if (e.target.value === "") {
      return;
    }
    let PO = posDetails?.filter(
      (item) => item.purchase_order === e.target.value
    );
    if (PO.length === 0) {
      return;
    }
    setSelectedPO(PO);
    setValue("partner_gstno", PO ? PO[0]?.supplier_gstin : "NA");
    let supplierList = suppliersData?.filter(
      (item) => item.gst_no === PO[0]?.supplier_gstin
    );
    if (supplierList.length !== 0) {
      setValue(
        "partner_address",
        supplierList[0]["street_house_no"] +
          " " +
          supplierList[0]["street_2"] +
          " " +
          supplierList[0]["street_3"] +
          "," +
          stateMasterCode[supplierList[0].gst_no.substring(0, 2)] +
          "-" +
          supplierList[0]["postal_code_city"]
      );
      setValue("partner_panno", supplierList[0].pan_no);
      setValue(
        "partner_state_name",
        stateMasterCode[supplierList[0].gst_no.substring(0, 2)]
      );
      setValue("partner_email", supplierList[0].email_address);
      setValue("partner_gst_status", supplierList[0].status);
      setValue("remaining_amount", PO[0]?.remaining_balance);
      setValue("po_date", moment(PO[0]?.created_at).format("DD/MM/YYYY"));
      setValue("partner_msme_no", PO[0]?.msme_no);
    }
  };
  const handleSupplierGstNo = (e) => {
    if (e.target.value === "") {
      return;
    }
    const supplierList = suppliersData?.filter(
      (item) => item.gst_no === e.target.value
    );
    // if (supplierList.length !== 0) {
    //   setValue(
    //     "partner_address",
    //     supplierList[0]["street_house_no"] +
    //       " " +
    //       supplierList[0]["street_2"] +
    //       " " +
    //       supplierList[0]["street_3"] +
    //       "," +
    //       stateMasterCode[supplierList[0].gst_no.substring(0, 2)] +
    //       "-" +
    //       supplierList[0]["postal_code_city"]
    //   );
    //   setValue("partner_panno", supplierList[0].pan_no);
    //   setValue(
    //     "partner_state_name",
    //     stateMasterCode[supplierList[0].gst_no.substring(0, 2)]
    //   );
    //   setValue("partner_email", supplierList[0].email_address);
    //   setValue("partner_gst_status", supplierList[0].status);
    //   setValue("partner_gstno", supplierList[0].gst_no);
    //   setValue("partner_msme_no", supplierList[0]?.msme_no);
    // }

    const isURD = URDcheck === "URD";
    if (supplierList.length !== 0) {
      const address = `${supplierList[0]["street_house_no"]} ${supplierList[0]["street_2"]} ${supplierList[0]["street_3"]}`;

      const addressWithState = isURD
        ? address
        : `${address} ${
            stateMasterCode[supplierList[0].gst_no.substring(0, 2)]
          }-${supplierList[0]["postal_code_city"]}`;

      setValue("partner_address", addressWithState);
      setValue("partner_panno", supplierList[0].pan_no);

      if (isURD) {
        setValue("partner_state_name", "");
      } else {
        setValue(
          "partner_state_name",
          stateMasterCode[supplierList[0].gst_no.substring(0, 2)]
        );
      }

      setValue("partner_email", supplierList[0].email_address);
      setValue("partner_gst_status", supplierList[0].status);
      setValue("partner_gstno", supplierList[0].gst_no);
      setValue("partner_msme_no", supplierList[0]?.msme_no);
    }
  };

  const handleMSME = (e) => {
    if (e.target.value === "") {
      setValue("partner_msme_status", e.target.value);
      setShowMSME(0);
      setValue("partner_msme_no", "");
      setdisableMSMENo(0);
    } else if (e.target.value === "Yes") {
      setdisableMSMENo(0);
      setValue("partner_msme_status", e.target.value);
      setValue("partner_msme_no", suppliersData[0]?.msme_no);
      if (suppliersData[0]?.is_msme_certificate === null) {
        setShowMSME(1);
      } else {
        setShowMSME(0);
      }
    } else if (e.target.value === "No") {
      setValue("partner_msme_status", e.target.value);
      setShowMSME(0);
      setValue("partner_msme_no", "NA");
      setdisableMSMENo(1);
    }
  };
  /**- PO-Suppliers Methods Ends -**/

  /**- Invoice Methods Begins -**/
  const [isValidInvoiceValue, setIsValidInvoiceValue] = useState(1);

  const handleInvoiceValues = (e) => {
    let { name, value } = e.target;
    // if (parseInt(value) >= 1000000000) {
    //   // console.log(typeof parseInt(value), typeof 1000000000);
    //   setValue(name, 0);
    //   setValue("total_invoice_amount", 0);
    //   return;
    // }

    if (value[value.length - 1] === ".") {
      setValue(name, value);
      if (value[value.length - 2] !== 0) {
        setValue(name, value);
      }
    } else {
      let n = value;
      let cntDecimals = value?.split(".")[1]?.length;
      if (value.includes(".") && cntDecimals >= 2) {
        n = parseFloat(value).toFixed(2);
        setValue(name, (n ? n : 0).toString());
      } else {
        if (
          value[value.length - 2] === "." &&
          value[value.length - 1] === "0"
        ) {
          setValue(name, value);
        } else {
          n = parseFloat(parseFloat(n));
          setValue(name, (n ? n : 0.0).toString());
        }
      }
    }
    let basic = getValues("basic_invoice_value");
    let dis = getValues("discount");
    let commodity_charges = getValues("commodity_charges");
    let customs_duty_others = getValues("customs_duty_others");
    let assessable_value =
      parseFloat(basic) - parseFloat(dis) + parseFloat(commodity_charges);

    let taxable_value = (
      parseFloat(assessable_value) + parseFloat(customs_duty_others)
    ).toFixed(2);
    let cgst = parseFloat(getValues("cgst"));
    let sgst = parseFloat(getValues("sgst"));
    let igst = parseFloat(getValues("igst"));
    let utgst = parseFloat(getValues("utgst"));
    let cess = parseFloat(getValues("cess"));

    cgst = parseFloat(getValues("cgst"));
    sgst = parseFloat(getValues("sgst"));
    igst = parseFloat(getValues("igst"));
    utgst = parseFloat(getValues("utgst"));

    let taxamount = (cgst + sgst + igst + utgst + cess).toFixed(2);
    // let total_invoice_amount =
    //   parseFloat(taxable_value) + parseFloat(taxamount) + parseFloat(cess);
    let total_invoice_amount =
      parseFloat(taxable_value) + parseFloat(taxamount);
    let upper = Math.ceil(total_invoice_amount).toFixed(2);
    let lower = Math.floor(total_invoice_amount).toFixed(2);

    let roundOff = total_invoice_amount - Math.floor(total_invoice_amount);

    //  roundOff =
    //   e.target.name === "round_off_value"
    //     ? getValues("round_off_value")
    //     : setValue("round_off_value", roundOff.toFixed(2));

    // if (roundOff >= 0.5 && roundOff <= 0.99) {
    //   total_invoice_amount = upper;
    // } else if (roundOff >= 0.01 && roundOff < 0.5) {
    //   total_invoice_amount = lower;
    // } else {
    //   total_invoice_amount = parseFloat(total_invoice_amount);
    // }

    let manualRoundOff = parseFloat(getValues("round_off_value") || 0);
    if (e.target.name === "round_off_value") {
      roundOff = manualRoundOff;
      total_invoice_amount = parseFloat(total_invoice_amount) + roundOff;
    } else {
      roundOff = roundOff.toFixed(2);
      setValue("round_off_value", roundOff);

      if (roundOff >= 0.5 && roundOff <= 0.99) {
        total_invoice_amount = upper;
      } else if (roundOff >= 0.01 && roundOff < 0.5) {
        total_invoice_amount = lower;
      } else {
        total_invoice_amount = parseFloat(total_invoice_amount);
      }

      if (manualRoundOff !== 0) {
        total_invoice_amount =
          parseFloat(total_invoice_amount) + manualRoundOff;
      }
    }

    setValue("assessable_value", assessable_value.toFixed(2));
    setValue("taxable_value_of_invoice", taxable_value);
    setValue("total_tax_amount", taxamount);

    // if (total_invoice_amount >= 1000000000) {
    //   setValue("total_invoice_amount", 0); //toFixed will cause error
    // } else {
    //   setValue("total_invoice_amount", total_invoice_amount); //toFixed will cause error
    // }

    setValue("total_invoice_amount", total_invoice_amount);

    setValue(
      "total_invoice_amount_in_words",
      toWords.convert(
        getValues("total_invoice_amount")
          ? getValues("total_invoice_amount")
          : "0"
      ) + " Rupees Only"
    );

    if (
      //getValues("total_invoice_amount") >
      getValues("taxable_value_of_invoice") >
      parseFloat(getValues("remaining_amount"))
    ) {
      setIsValidInvoiceValue(0);
      //notify(); //akshay this cause error in popup in the draft
    } else {
      setIsValidInvoiceValue(1);
    }
    if (getValues("po_type") === "Non-PO") {
      if (getValues("total_invoice_amount") > 250000) {
        // console.log(typeof parseInt(value), typeof 1000000000);
        setValue("total_invoice_amount", 0);
        setValue(
          "total_invoice_amount_in_words",
          toWords.convert(
            getValues("total_invoice_amount")
              ? getValues("total_invoice_amount")
              : "0"
          ) + " Rupees Only"
        );
        notifyNonPO();
        setIsValidInvoiceValue(0);
        return;
      } else {
        setIsValidInvoiceValue(1);
      }
    }

    //test EL validations
    if (getValues("po_type") === "Engagement-Letter") {
      if (getValues("total_invoice_amount") > 20000000) {
        setValue("total_invoice_amount", 0);
        setValue(
          "total_invoice_amount_in_words",
          toWords.convert(
            getValues("total_invoice_amount")
              ? getValues("total_invoice_amount")
              : "0"
          ) + " Rupees Only"
        );
        notifyEL();
        setIsValidInvoiceValue(0);
        return;
      } else {
        setIsValidInvoiceValue(1);
      }
    }
    //

    //test Non-Po under 25000 validations
    if (getValues("po_type") === "Misc-Spent") {
      if (getValues("total_invoice_amount") > 25000) {
        setValue("total_invoice_amount", 0);
        setValue(
          "total_invoice_amount_in_words",
          toWords.convert(
            getValues("total_invoice_amount")
              ? getValues("total_invoice_amount")
              : "0"
          ) + " Rupees Only"
        );
        notifyNonPOunder();
        setIsValidInvoiceValue(0);
        return;
      } else {
        setIsValidInvoiceValue(1);
      }
    }
    //
  };

  const [isRCM, setRCM] = useState(0);
  const handleGSTRCM = (e) => {
    if (e.target.value === "") {
      setRCM(0);
    } else if (e.target.value === "Yes") {
      setRCM(1);
      setValue("cgst", "0");
      setValue("sgst", "0");
      setValue("igst", "0");
      setValue("utgst", "0");
    } else if (e.target.value === "No") {
      setRCM(0);
    }
  };
  /**- Invoice Methods Ends -**/

  /**- Stepper Begins -**/
  const [invoicemessage, setInvoiceMessage] = useState("");
  const [current, setCurrent] = useState(0);
  const isStepComplete = (currentStep) => current > currentStep;
  const steps = [
    {
      title: "Business Details",
      content: "Business Details",
    },
    {
      title: "Supplier Details",
      content: "Supplier Details",
    },
    {
      title: "Invoice Details",
      content: "Invoice Details",
    },
  ];

  const handleNext = (data, event) => {
    event.preventDefault();

    if (current === steps.length - 1) {
      if (getValues("basic_invoice_value") < 1) {
        return;
      }
      //handleInvoiceValues();
      const formData = new FormData();
      Object.keys(data).forEach((key) => formData.append(key, data[key]));

      (async () => {
        try {
          setLoading(true);
          setError(false);

          // console.log(JSON.stringify(formData));

          if (selectedInvoice) {
            if (formData.get("invoice_status") == "Draft") {
              formData.delete("invoice_status");
            }

            formData.append("invoice_status", "Submitted");
            //formData.append("invoice_copy", data?.invoiceFile[0]);
            //formData.append("supporting_documents", data?.supportFile[0]);
            const response = await InvoiceAPI.updateInvoiceDraft(formData);

            // console.log("Response for selected Invoice Handle Next:",response?.data);
          } else {
            formData.append("invoice_copy", data?.invoiceFile[0]);
            formData.append("supporting_documents", data?.supportFile[0]);

            // userID[0];
            formData.append("partner_code", userID);
            formData.append("invoice_status", "Submitted");
            const response = await InvoiceAPI.uploadInvoice(formData);
            //console.log("Response for Non-selected Invoice Handle Next",response?.data );
          }

          setInvoiceSuccess({ isInvoiceUploaded: "true", isDraft: "false" });
          setLoading(true);
          setCurrentTab(0);

          Invoices(userID, getValues("business_code"));
          // setTimeout(() => {
          //   setLoading(true);
          // }, 2000);
          //console.log("response::",response);
          // setInvoiceMessage(response?.data?.message);
          setLoading(false);
        } catch (error) {
          //console.log("Error::", error.response.data);

          if (error.response) {
            setErrorMessage(
              error?.response?.data?.message ||
                error?.response?.data?.error_message?.code
              // console.log("Error::", error)
            );
            setInvoiceMessage(
              error?.response?.data?.message ||
                error?.response?.data?.error_message?.code
            );
          } else {
            setErrorMessage(
              "Internal Server Isssue,Apologies for the inconvenience caused!"
            );
            setInvoiceMessage(
              "Internal Server Isssue,Apologies for the inconvenience caused!"
            );
          }
          //setInvoiceSuccess(0);
          setInvoiceSuccess({ isInvoiceUploaded: "false", isDraft: "false" });
          //  setInvoiceSuccess({ isInvoiceUploaded: false, isDraft: false });

          setCurrentTab(0);
          setError(true);
          setLoading(false);
        }
      })();
    }
    // userID[0])
    Invoices(userID);
    setCurrent(current + 1);
  };

  const handleBack = () => {
    setCurrent(current - 1);
  };

  const handleInvoice = () => {
    setCurrent(2);
  };
  /**- Stepper Ends -**/

  /**- Notify-**/
  const notify = () =>
    toast.warn(<h4>Invoice Amount Can't exceed remaining PO Amount!</h4>, {
      autoClose: 3000,
      pauseOnHover: true,
      draggable: true,
      position: "bottom-right",
    });

  // change value to 2.5 Lakh
  const [isToastonce, setIsToastOnce] = useState(false);

  const notifyNonPO = () => {
    if (!isToastonce) {
      setIsToastOnce(true);
      toast.warn(<h4>Non PO Invoice Amount Can't exceed 2.5 Lakh!</h4>, {
        autoClose: 2000,
        pauseOnHover: false,
        draggable: false,
        position: "bottom-right",
        onClose: () => setIsToastOnce(false),
      });
    }
  };

  const notifyEL = () => {
    if (!isToastonce) {
      setIsToastOnce(true);
      toast.warn(<h4>Engagement Letter Amount Can't exceed 2 Crore!</h4>, {
        autoClose: 2000,
        pauseOnHover: false,
        draggable: false,
        position: "bottom-right",
        onClose: () => setIsToastOnce(false),
      });
    }
  };

  const notifyNonPOunder = () => {
    if (!isToastonce) {
      setIsToastOnce(true);
      toast.warn(<h4>Miscellaneous spent Amount Can't exceed 25000 ₹ !</h4>, {
        autoClose: 2000,
        pauseOnHover: false,
        draggable: false,
        position: "bottom-right",
        onClose: () => setIsToastOnce(false),
      });
    }
  };

  const notifyFill = () =>
    toast.warn(<h4>Basic Invoice Value & Document No Required To Draft</h4>, {
      autoClose: 3000,
      pauseOnHover: true,
      draggable: true,
      position: "bottom-right",
    });

  //code to eliminate the error
  const handleDraft = async (data, event) => {
    if (event) event.preventDefault();
    data = getValues();

    if (current === steps.length - 1) {
      if (
        getValues("basic_invoice_value") < 1 ||
        getValues("document_no") < 1
      ) {
        notifyFill();
        return;
      }

      const formData = new FormData();
      Object.keys(data).forEach((key) => formData.append(key, data[key]));

      const invoiceFile = data?.invoiceFile && data.invoiceFile[0];
      const supportFile = data?.supportFile && data.supportFile[0];

      if (selectedInvoice) {
        // If editing an existing draft, no need to append new files unless they are changed
        if (invoiceFile) formData.append("invoice_copy", invoiceFile);
        if (supportFile) formData.append("supporting_documents", supportFile);

        // if (invoiceFile) {
        //   console.log("Invoice file chosen: " + invoiceFile.name);
        // }

        // if (supportFile) {
        //   console.log("Supporting document file chosen: " + supportFile.name);
        // }
        try {
          setLoading(true);
          setError(false);
          //console.log("Updating invoice inside dashboard");

          const response = await InvoiceAPI.updateInvoiceDraft(formData);
          //console.log("Response for selected Draft:", response?.data);
          setUploadedInvoiceFile(
            invoiceFile?.name || selectedInvoice.invoiceFile
          );
          setUploadedSupportFile(
            supportFile?.name || selectedInvoice.supportFile
          );

          setInvoiceSuccess({ isInvoiceUploaded: "true", isDraft: "true" });
          //  setInvoiceSuccess({ isInvoiceUploaded: true, isDraft: true });
          Invoices(userID, getValues("business_code"));
          setCurrentTab(0);
          setLoading(false);
        } catch (error) {
          handleApiError(error);
        }
      } else {
        if (invoiceFile) formData.append("invoice_copy", invoiceFile);
        if (supportFile) formData.append("supporting_documents", supportFile);
        formData.append("partner_code", userID);
        formData.append("invoice_status", "Draft");

        // if (invoiceFile) {
        //   console.log("Invoice file chosen: " + invoiceFile.name);
        // }

        // if (supportFile) {
        //   console.log("Supporting document file chosen: " + supportFile.name);
        // }

        try {
          setLoading(true);
          setError(false);

          const response = await InvoiceAPI.uploadInvoice(formData);
          // console.log("Response for Non-selected Draft:", response?.data);

          setUploadedInvoiceFile(invoiceFile?.name);
          setUploadedSupportFile(supportFile?.name);

          setInvoiceSuccess({ isInvoiceUploaded: "true", isDraft: "true" });
          //setInvoiceSuccess({ isInvoiceUploaded: true, isDraft: true });
          Invoices(userID, getValues("business_code"));
          setCurrentTab(0);
          setLoading(false);
        } catch (error) {
          handleApiError(error);
        }
      }

      setCurrent(current + 1);
    }
  };

  const handleApiError = (error) => {
    //console.log("error::", error.response?.data);
    const errorMessage =
      error.response?.data?.message ||
      error.response?.data?.error_message?.code ||
      "Internal Server Issue, Apologies for the inconvenience caused!";

    setErrorMessage(errorMessage);
    setInvoiceMessage(errorMessage);
    setInvoiceSuccess({ isInvoiceUploaded: "false", isDraft: "false" });
    //setInvoiceSuccess({ isInvoiceUploaded: false, isDraft: false });
    setCurrentTab(0);
    setError(true);
    setLoading(false);
  };

  return (
    <>
      <ToastContainer />
      <div className="progress-bar-form-wrapper">
        {/* STEPPER START */}
        <div className="progress-bar-wrapper">
          <div className="arrow-steps">
            {steps.map((item, i) => (
              <div
                key={i}
                className={
                  current === i ? "arrow-steps step current" : "step done"
                }
              >
                {isStepComplete(i) ? "✔ " : ""}
                {item.title}
              </div>
            ))}
          </div>
          <img src={logo} className="logo-class" alt="" />
        </div>
        {/**- STEPPER END -**/}
        {/* FORM START*/}
        <>
          {loading && <Loading />}
          {/* <form onSubmit={handleSubmit(handleNext)}> */}
          <form>
            {current === 0 ? (
              <>
                <div className="invoice-form-container">
                  <div className="invoice-form-fields">
                    <div className="invoice-form-input-field">
                      <label className="required">Select Business</label>
                      <select
                        {...register("business_code", {
                          required: { value: true, message: "*required" },
                        })}
                        onChange={changeBusiness}
                      >
                        <option value="">Select Business</option>
                        {businessCodeList?.map((business, i) => (
                          <option
                            key={i}
                            value={business.business_code}
                            selected={
                              business.business_code ===
                              selectedInvoice?.business_code
                            }
                          >
                            {business.business_code}
                          </option>
                        ))}
                      </select>
                      <div className="invoice-form-input-field-extra-holder">
                        {errors?.business_code && (
                          <p className="errorMsg">
                            {errors?.business_code.message}
                          </p>
                        )}
                        {/* <p className="errorMsg">{errorMessage}</p> */}
                      </div>
                    </div>
                    <div className="invoice-form-input-field">
                      <label className="required">Business Name</label>
                      <input
                        type="text"
                        placeholder="Business Name"
                        {...register("business_name", {
                          required: { value: true, message: "*required" },
                        })}
                      />
                      <div className="invoice-form-input-field-extra-holder">
                        {errors?.business_name && (
                          <p className="errorMsg">
                            {errors?.business_name.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="invoice-form-input-field">
                      <label className="required">Business PAN No</label>
                      <input
                        type="text"
                        placeholder="Business PAN NO"
                        name="business_panno"
                        {...register("business_panno", {
                          required: "*required",
                        })}
                      />
                      <div className="invoice-form-input-field-extra-holder">
                        {errors?.business_panno && (
                          <p className="errorMsg">
                            {errors?.business_panno.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="invoice-form-input-field">
                      <label className="required">Business GST No</label>
                      <select
                        {...register("business_gstno", {})}
                        onChange={changebusinessGstNo}
                      >
                        <option value="">Select State GST</option>
                        {businessAllGst?.map((p, i) => (
                          <option
                            key={i}
                            value={p}
                            selected={p === selectedInvoice?.business_gstno}
                          >
                            {p}
                          </option>
                        ))}
                      </select>
                      <div className="invoice-form-input-field-extra-holder">
                        {errors?.business_gstno && (
                          <p className="errorMsg">
                            {errors?.business_gstno.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="invoice-form-input-field">
                      <label className="required">Billing Address</label>
                      <input
                        type="text"
                        placeholder="Billing Address"
                        {...register("business_billing_address", {
                          required: "*billing address required",
                        })}
                      />
                      <div className="invoice-form-input-field-extra-holder">
                        {errors?.business_billing_address && (
                          <p className="errorMsg">
                            {errors?.business_billing_address.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="invoice-form-input-field">
                      <label className="required">Business State Name</label>
                      <input
                        type="text"
                        placeholder="Business State Name"
                        {...register("business_state_name", {
                          required: "*required",
                        })}
                      />
                      <div className="invoice-form-input-field-extra-holder">
                        {errors?.business_state_name && (
                          <p className="errorMsg">
                            {errors?.business_state_name.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="invoice-form-input-field">
                      <label className="required">Business Email</label>
                      <input
                        type="email"
                        placeholder="Business Email"
                        {...register("business_email", {
                          required: true,
                          pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        })}
                      />
                      <div className="invoice-form-input-field-extra-holder">
                        {errors.business_email &&
                          errors.business_email.type === "required" && (
                            <p className="errorMsg">Email is required.</p>
                          )}
                        {errors.business_email &&
                          errors.business_email.type === "pattern" && (
                            <p className="errorMsg">Email is not valid.</p>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : current === 1 ? (
              <>
                <div className="invoice-form-container">
                  <div>
                    <div className="invoice-form-fields">
                      <div className="invoice-form-input-field">
                        <label className="required">Supplier PAN No</label>
                        <input
                          type="text"
                          placeholder="Supplier PAN No"
                          {...register("partner_panno", {
                            required: "*required",
                          })}
                          readOnly={showPoType !== 3}
                        />
                        <div className="invoice-form-input-field-extra-holder">
                          {errors?.partner_panno && (
                            <p className="errorMsg">
                              {errors?.partner_panno.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="invoice-form-input-field">
                        <label className="required">Select PO Type</label>
                        <select
                          {...register("po_type", { required: "*required" })}
                          onChange={handlePOType}
                        >
                          <option value="">Select PO Type</option>
                          <option value="PO">PO</option>
                          <option value="Non-PO">Non-PO</option>
                          <option value="Engagement-Letter">
                            Engagement-Letter
                          </option>
                          {userID === checkUserID && (
                            <option value="Misc-Spent">
                              Miscellaneous Spent
                            </option>
                          )}
                        </select>
                        <div className="invoice-form-input-field-extra-holder">
                          {errors?.po_type && (
                            <p className="errorMsg">
                              {errors?.po_type.message}
                            </p>
                          )}
                        </div>
                      </div>
                      {/* openlogic begin*/}
                      {showPoType === 1 ? (
                        <div className="invoice-form-input-field">
                          <label className="required">Select OPEN PO </label>
                          <select
                            {...register("selected_purchase_order", {
                              required: "*required",
                            })}
                            onChange={handleOpenPO}
                          >
                            <option value="">Select OPEN</option>
                            {POName?.map((p, i) => (
                              <option value={POName[i]}>{POName[i]}</option>
                            ))}
                          </select>
                          <div className="invoice-form-input-field-extra-holder">
                            {errors?.selected_purchase_order && (
                              <p className="errorMsg">
                                {errors?.selected_purchase_order.message}
                              </p>
                            )}
                          </div>
                        </div>
                      ) : null}

                      {showPoType === 1 ? (
                        <div className="invoice-form-input-field">
                          <label>Remaining PO Amount</label>
                          <input
                            className="invoice-details-table-remaining-amount po-input"
                            type="text"
                            {...register("remaining_amount")}
                            readOnly
                          />
                        </div>
                      ) : null}
                      {showPoType === 1 ? (
                        <div className="invoice-form-input-field">
                          <label className="required">PO Date</label>
                          <input
                            className="po-input"
                            type="text"
                            {...register("po_date")}
                            readOnly
                          />
                        </div>
                      ) : null}

                      {showPoType === 2 ? (
                        <div className="invoice-form-input-field">
                          <label className="required">EL Date</label>
                          <input
                            className="po-input"
                            type="date"
                            {...register("po_date", {
                              required: "*required",
                              pattern: {
                                value: /^\d{4}-\d{2}-\d{2}$/,
                                message: "Invalid date format",
                              },
                            })}
                          />
                        </div>
                      ) : null}

                      <div className="invoice-form-input-field">
                        <label className="required">GST No.</label>
                        <select
                          {...register("partner_gstno", {
                            required: "*required",
                          })}
                          onChange={handleSupplierGstNo}
                          disabled={disableGST === 0 ? false : true}
                        >
                          <option value="">Select State GST</option>
                          {supplierAllGst?.map((p, i) => (
                            <option
                              key={i}
                              value={p}
                              selected={p === selectedInvoice?.partner_gstno}
                            >
                              {p}
                            </option>
                          ))}
                        </select>
                        <div className="invoice-form-input-field-extra-holder">
                          {errors?.partner_gstno && (
                            <p className="errorMsg">
                              {errors?.partner_gstno.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="invoice-form-input-field">
                        <label className="required">Name of Supplier</label>
                        <input
                          type="text"
                          placeholder="Name of Supplier"
                          {...register("partner_name", {
                            required: "*required",
                          })}
                        />
                        <div className="invoice-form-input-field-extra-holder">
                          {errors?.partner_name && (
                            <p className="errorMsg">
                              {errors?.partner_name?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="invoice-form-input-field">
                        <label className="required">Address of Supplier</label>
                        <input
                          type="text"
                          placeholder="Address of Supplier"
                          {...register("partner_address", {
                            required: "*required",
                          })}
                        />
                        <div className="invoice-form-input-field-extra-holder">
                          {errors?.partner_address && (
                            <p className="errorMsg">
                              {errors?.partner_address?.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="invoice-form-input-field">
                        <label className="required">State Name</label>
                        <input
                          type="text"
                          placeholder="State Name"
                          {...register("partner_state_name", {
                            required: true,
                          })}
                        />
                        <div className="invoice-form-input-field-extra-holder">
                          {errors?.partner_state_name && (
                            <p className="errorMsg">
                              {errors?.partner_state_name?.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="invoice-form-input-field">
                        <label className="required">Email</label>
                        <input
                          type="email"
                          placeholder="Email"
                          {...register("partner_email", {
                            required: "*requied",
                          })}
                        />
                        <div className="invoice-form-input-field-extra-holder">
                          {errors?.partner_email && (
                            <p className="errorMsg">
                              {errors?.partner_email?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="invoice-form-input-field">
                        <label className="required">Supplier GST status</label>
                        <input
                          type="text"
                          {...register("partner_gst_status", {
                            required: "*required",
                          })}
                          placeholder="Supplier GST status"
                        ></input>
                        <div className="invoice-form-input-field-extra-holder">
                          {errors?.partner_gst_status && (
                            <p className="errorMsg">
                              {errors?.partner_gst_status?.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="invoice-form-input-field">
                        <lable className="required">Type of supply </lable>
                        <select
                          {...register("partner_type", {
                            required: "*required",
                          })}
                        >
                          <option value="">
                            Type of supply i.e. B2B or B2C
                          </option>
                          <option value="B2B">B2B</option>
                          <option value="B2C">B2C</option>
                        </select>
                        <div className="invoice-form-input-field-extra-holder">
                          {errors?.partner_type && (
                            <p className="errorMsg">
                              {errors?.partner_type?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="invoice-form-input-field-select">
                        <lable className="required">MSME Status</lable>
                        <select
                          {...register("partner_msme_status", {
                            required: "*required",
                          })}
                          onChange={handleMSME}
                        >
                          <option value="">Select MSME Status</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>

                        <div className="invoice-form-input-field-extra-holder">
                          {showMSME ? (
                            <>
                              <label>Upload MSME Certificate</label>
                              <input
                                type="file"
                                {...register("msmeCertificate", {
                                  required: "*required",
                                })}
                                placeholder="MSME CERTIFICATE"
                              />
                            </>
                          ) : null}
                          {errors?.partner_msme_status && (
                            <p className="errorMsg">
                              {errors?.partner_msme_status?.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="invoice-form-input-field-select">
                        <label className="required">MSME No</label>
                        <input
                          type="text"
                          {...register("partner_msme_no", {
                            required: "*required",
                          })}
                          placeholder="MSME NO"
                          disabled={disableMSMENo}
                        ></input>
                        <div className="invoice-form-input-field-extra-holder">
                          {errors?.partner_msme_no && (
                            <p className="errorMsg">
                              {errors?.partner_msme_no?.message}
                            </p>
                          )}
                        </div>
                        {/* <label>MSME CODE - IS MSME CERTIFICATE UPLOADED</label>
                      <label>MSME CERTIFICATE</label> */}
                      </div>

                      <div className="invoice-form-input-field">
                        {suppliersData?.is_gst_certificate}
                        {suppliersData?.is_gst_certificate === null ? (
                          <div className="invoice-form-input-field-extra-holder">
                            <label className="required">GST Certificate</label>
                            <input
                              type="file"
                              {...register("isGSTCertificate")}
                              accept="application/pdf"
                            />
                            <div className="invoice-form-input-field-extra-holder">
                              {errors.isGSTCertificate &&
                                errors.isGSTCertificate.message}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="invoice-form-input-field">
                        <div className="invoice-form-input-field-extra-holder">
                          {showMSME ? (
                            <>
                              <label>Upload MSME Certificate</label>
                              <input
                                type="file"
                                {...register("msmeCertificate", {
                                  required: "*required",
                                })}
                                placeholder="MSME CERTIFICATE"
                                accept="application/pdf"
                              />
                            </>
                          ) : null}
                          {/* {errors.msmeCertificate && errors.msmeCertificate.message}
                {errors.partner_msme_status &&
                  errors.partner_msme_status.message} */}
                        </div>
                      </div>
                      <div className="invoice-form-input-field"></div>
                      <div className="invoice-form-input-field"></div>
                    </div>
                  </div>
                </div>
              </>
            ) : current === 2 ? (
              <>
                <div className="invoice-form-container">
                  <div>
                    {/* <div className="invoice-from-container"> */}
                    {/* <div className="invoice-from">
                          <div className="invoice-from-field">
                            <label>
                              <h4>SOLD BY:</h4>
                            </label>
                            <textarea
                              rows={5}
                              value={
                                getValues("partner_name") +
                                ", \n" +
                                getValues("partner_address")
                              }
                              readOnly
                            />
                          </div>
                          <div className="invoice-from-field">
                            <label>Pan No:</label>
                            <input
                              value={getValues("partner_panno")}
                              readOnly
                            />
                          </div>
                          <div className="invoice-from-field">
                            <label>GST No:</label>
                            <input
                              value={getValues("partner_gstno")}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="invoice-logo">
                          <p style={{ color: "white" }}>
                            {getValues("partner_name")}
                          </p>
                        </div>
                      </div> */}
                    <div className="invoice-business-address">
                      <div className="invoice-billing">
                        <div className="invoice-billing-field">
                          <label>
                            <h4 className="required">BILLING ADDRESS:</h4>
                          </label>
                          <textarea
                            type="text"
                            {...register("business_billing_address", {
                              required: "*required",
                            })}
                            defaultValue={getValues("business_billing_address")}
                            rows={5}
                            readOnly
                          ></textarea>
                          {/* <div className="invoice-form-input-field-extra-holder">
                              {errors?.business_shipping_address && (
                                <p className="errorMsg">
                                  {errors?.business_shipping_address.message}
                                </p>
                              )} */}
                          {/* </div> */}
                        </div>
                        <div className="invoice-billing-field">
                          <label className="required" htmlFor="">
                            Billing State Name:
                          </label>
                          <input
                            type="text"
                            defaultValue={getValues("business_state_name")}
                            readOnly
                          />
                        </div>
                        {/* <div className="invoice-billing-field">
                            <label>Billing State/UT Code</label>
                            <input type="text" />
                          </div> */}
                      </div>
                      <div className="invoice-shipping">
                        <div className="invoice-shipping-field">
                          <label>
                            <h4 className="required">SHIPPING ADDRESS:</h4>
                          </label>
                          {/* <textarea rows={5}></textarea> */}
                          <textarea
                            type="text"
                            {...register("business_shipping_address", {
                              required: "*required",
                            })}
                            defaultValue={getValues("business_billing_address")}
                            rows={5}
                          ></textarea>
                        </div>
                        <div className="invoice-shipping-field">
                          <label className="required">Shipping State:</label>
                          <input
                            type="text"
                            defaultValue={getValues("business_state_name")}
                          />
                        </div>
                        {/* <div className="invoice-shipping-field">
                            <label>Shipping State/UT Code:</label>
                            <input type="text" />
                          </div> */}
                      </div>
                      <div className="invoice-po-meta">
                        <div className="invoice-po-meta-field">
                          <label className="required">Document Type :</label>
                          <div className="invoice-po-meta-input-field">
                            <select
                              {...register("document_type", {
                                required: "*required",
                              })}
                            >
                              <option value="">Document Type</option>
                              <option value="INV">Invoice</option>
                              <option value="DBN">Debit note</option>
                              <option value="CRN">Credit note</option>
                            </select>
                            <div className="invoice-form-input-field-extra-holder">
                              {errors?.document_type && (
                                <p className="errorMsg">
                                  {errors?.document_type.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="invoice-po-meta-field">
                          <label className="required">INV/DBN/CRN No:</label>
                          <div className="invoice-po-meta-input-field">
                            <input
                              type="text"
                              placeholder="Enter Document No"
                              {...register("document_no", {
                                required: "*required",
                                pattern: {
                                    value: /^[a-zA-Z0-9_\/-]*$/,
                                    message:
                                      "Only letters, numbers, '_', '-' and '/' are allowed",
                                  },
                              })}
                            />
                            <div className="invoice-form-input-field-extra-holder">
                              {errors?.document_no && (
                                <p className="errorMsg">
                                  {errors?.document_no.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="invoice-po-meta-field">
                          <label className="required">INV/DBN/CRN Date:</label>
                          <div className="invoice-po-meta-input-field">
                            <input
                              type="date"
                              {...register("document_date", {
                                required: "*required",
                              })}
                              min={moment(new Date())
                                .subtract(60, "days")
                                .format("YYYY-MM-DD")}
                              max={moment(new Date()).format("YYYY-MM-DD")}
                              value={document_date}
                              onKeyDown={(e) => e.preventDefault()}
                              onChange={(e) => setDocumentDate(e.target.value)}
                            />
                            <div className="invoice-form-input-field-extra-holder">
                              {errors?.document_date && (
                                <p className="errorMsg">
                                  {errors?.document_date.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="invoice-po-meta-field">
                          <label>PO.No:</label>
                          <div className="invoice-po-meta-input-field">
                            {getValues("po_type") !== "Non-PO" &&
                            getValues("po_type") !== "Engagement-Letter"
                              ? getValues("selected_purchase_order")
                              : "Not Applicable"}
                          </div>
                        </div>
                        <div className="invoice-po-meta-field">
                          <label>P.O. DATE</label>
                          {/* <input
                              className="po-input"
                              type="text"
                              {...register("po_date")}
                              readOnly
                            /> */}
                          <div className="invoice-po-meta-input-field">
                            {getValues("po_type") !== "Non-PO" &&
                            getValues("po_type") !== "Engagement-Letter"
                              ? getValues("po_date")
                              : "Not Applicable"}
                          </div>
                        </div>

                        <div className="invoice-po-meta-field">
                          <label>Remaining PO Amount(in ₹) </label>
                          {/* <input value="90000"></input> */}
                          {/* <input
                              className="invoice-details-table-remaining-amount po-input"
                              type="text"
                              {...register("remaining_amount")}
                              readOnly
                            /> */}
                          <div className="invoice-po-meta-input-field">
                            {getValues("po_type") !== "Non-PO" &&
                            getValues("po_type") !== "Engagement-Letter"
                              ? getValues("remaining_amount")
                              : "Not Applicable"}
                          </div>
                        </div>
                        <div className="invoice-po-meta-field">
                          <label className="required">
                            Email ID Of Person who has issued PO:
                          </label>
                          <div className="invoice-po-meta-input-field">
                            <input
                              type="email"
                              placeholder="Contact Person Email"
                              {...register("contact_person_email_id", {
                                required: "*required",
                                pattern: {
                                  value:
                                    /^[a-zA-Z0-9._-]{2,200}@adityabirlacapital\.com$/i,
                                  message:
                                    "Entered email must be @adityabirlacapital.com and 200 characters",
                                },
                              })}
                            ></input>
                            <div className="invoice-form-input-field-extra-holder">
                              {errors?.contact_person_email_id && (
                                <p className="errorMsg">
                                  {errors?.contact_person_email_id.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="invoice-einvoice-invoice-values">
                      <div className="invoice-values">
                        <div className="invoice-values-field">
                          <label className="required">
                            Whether GST payable under RCM{" "}
                          </label>
                          <div className="invoice-values-input-field">
                            <select
                              {...register("gst_under_rcm", {
                                required: "*required",
                              })}
                              onChange={handleGSTRCM}
                            >
                              <option value="">Select GST RCM</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            <div className="invoice-form-input-field-extra-holder">
                              {errors?.gst_under_rcm && (
                                <p className="errorMsg">
                                  {errors?.gst_under_rcm.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="invoice-values-field">
                          <label>Basic invoice value (in ₹)</label>
                          <input
                            className="invoice-input-amount"
                            type="text"
                            maxLength={13}
                            placeholder="Basic invoice value"
                            {...register("basic_invoice_value", {
                              required: "*required",
                              pattern: {
                                value: /^[0-9]{1,10}((.)[0-9]{0,2}){0,1}$/,
                                message: "can't exceed 100 Cr",
                              },
                            })}
                            onChange={(e) => handleInvoiceValues(e)}
                            // onChange={handleInvoiceValues}
                          />
                          <div className="invoice-form-input-field-extra-holder">
                            {errors?.basic_invoice_value &&
                              errors?.basic_invoice_value.type ===
                                "pattern" && (
                                <p className="errorMsg">
                                  {errors?.basic_invoice_value.message}
                                </p>
                              )}
                            {errors?.basic_invoice_value &&
                              errors?.basic_invoice_value.type ===
                                "required" && (
                                <p className="errorMsg">
                                  {errors?.basic_invoice_value.message}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="invoice-values-field">
                          <label>Discount if any</label>
                          <input
                            className="invoice-input-amount"
                            type="text"
                            maxLength={13}
                            placeholder="Discount"
                            {...register("discount")}
                            onChange={(e) => handleInvoiceValues(e)}
                          />
                        </div>
                        <div className="invoice-values-field">
                          <label>
                            Transport Charge/Insurance Charge/Others
                          </label>
                          <input
                            className="invoice-input-amount"
                            type="text"
                            maxLength={13}
                            placeholder="TC/IC/Others"
                            {...register("commodity_charges")}
                            onChange={(e) => handleInvoiceValues(e)}
                          />
                        </div>
                        <div className="invoice-values-field">
                          <label> Assessable Value</label>
                          <input
                            className="invoice-input-amount"
                            type="text"
                            placeholder="Assessable value"
                            {...register("assessable_value")}
                            disabled
                          />
                        </div>
                        <div className="invoice-values-field">
                          <label>Customs Duty/CVD/SWC/Others</label>
                          <input
                            className="invoice-input-amount"
                            type="text"
                            maxLength={13}
                            placeholder="Customs duty"
                            {...register("customs_duty_others")}
                            onChange={(e) => handleInvoiceValues(e)}
                          />
                        </div>
                        <div className="invoice-values-field">
                          <label>Taxable Value</label>
                          <input
                            className="invoice-input-amount"
                            type="text"
                            placeholder="Taxable Value"
                            {...register("taxable_value_of_invoice")}
                            disabled
                          />
                        </div>
                        <div className="invoice-values-field">
                          <label>CGST Total</label>
                          <input
                            className="invoice-input-amount"
                            type="text"
                            maxLength={13}
                            placeholder="CGST Total"
                            {...register("cgst")}
                            disabled={isRCM ? true : false}
                            onChange={(e) => handleInvoiceValues(e)}
                          />
                        </div>
                        <div className="invoice-values-field">
                          <label>SGST Total</label>
                          <input
                            className="invoice-input-amount"
                            type="text"
                            maxLength={13}
                            placeholder="SGST Total"
                            {...register("sgst")}
                            disabled={isRCM ? true : false}
                            onChange={(e) => handleInvoiceValues(e)}
                          />
                        </div>
                        <div className="invoice-values-field">
                          <label>UTGST Total</label>
                          <input
                            className="invoice-input-amount"
                            type="text"
                            maxLength={13}
                            placeholder="UTGST Total"
                            {...register("utgst")}
                            disabled={isRCM ? true : false}
                            onChange={(e) => handleInvoiceValues(e)}
                          />
                        </div>
                        <div className="invoice-values-field">
                          <label>IGST Total</label>
                          <input
                            className="invoice-input-amount"
                            type="text"
                            maxLength={13}
                            placeholder="IGST Total"
                            {...register("igst")}
                            disabled={isRCM ? true : false}
                            onChange={(e) => handleInvoiceValues(e)}
                          />
                        </div>
                        <div className="invoice-values-field">
                          <label>Cess</label>
                          <input
                            className="invoice-input-amount"
                            type="text"
                            maxLength={13}
                            placeholder="Cess"
                            {...register("cess")}
                            onChange={(e) => handleInvoiceValues(e)}
                          />
                        </div>
                        <div className="invoice-values-field">
                          <label>Round off value (+/-)</label>
                          <input
                            className="invoice-input-amount"
                            type="text"
                            maxLength={13}
                            placeholder="Round off value"
                            {...register("round_off_value")}
                            onChange={(e) => handleInvoiceValues(e)}
                          />
                        </div>
                        <div className="invoice-values-field">
                          <label>Total Invoice amount(in ₹)</label>

                          <input
                            className="invoice-input-amount"
                            type="text"
                            // placeholder="Total Invoice amount"
                            // min="0"
                            {...register("total_invoice_amount", {
                              pattern: {
                                value: /^[0-9]{10}\.[0-9]{2}$/,
                                message: "invalid value",
                              },
                            })}
                            disabled
                          />
                          {/* {errors.total_invoice_amount && (
                              <p>{errors.total_invoice_amount.message}</p>
                            )} */}
                        </div>
                        <div className="invoice-values-field">
                          Total Tax amount(in ₹)
                          <input
                            className="invoice-input-amount"
                            type="text"
                            placeholder="Total Tax amount"
                            {...register("total_tax_amount")}
                            disabled
                          />
                        </div>
                        <div className="invoice-values-field">
                          <label>Total Invoice amount In Words:</label>
                          <textarea
                            type="text"
                            {...register("total_invoice_amount_in_words")}
                            cols={30}
                            rows={3}
                            readOnly
                            style={{ resize: "none" }}
                          />
                        </div>
                      </div>
                      <div className="einvoice-values">
                        <div className="einvoice-values-field">
                          <label className="required">
                            HSN Code [of main item]
                          </label>
                          <div className="einvoice-values-input-field">
                            <input
                              type="text"
                              placeholder="HSN Code"
                              {...register("hsncode_main_item", {
                                required: "*required",
                              })}
                            />
                            <div className="invoice-form-input-field-extra-holder">
                              {errors?.hsncode_main_item && (
                                <p className="errorMsg">
                                  {errors?.hsncode_main_item.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="einvoice-values-field">
                          <label className="required">
                            Upload invoice copy
                          </label>
                          <div className="einvoice-values-input-field">
                            <input
                              type="file"
                              accept="application/pdf"
                              // value={selectedFiles["invoiceFile"]}
                              {...register("invoiceFile", {
                                required: "*document required",
                              })}
                              //onChange={handleFile}
                            />
                            {/* {selectedInvoice?.invoice_copy ? (
                                selectedInvoice?.invoice_copy
                              ) : (
                                <input
                                  type="file"
                                  accept="application/pdf"
                                  // value={selectedFiles["invoiceFile"]}
                                  {...register("invoiceFile", {
                                    required: "*document required",
                                  })}
                                  //onChange={handleFile}
                                  //onChange={(e) => setValue('invoiceFile', e.target.files[0])} //akshay
                                />
                              )} */}
                            {/* {selectedInvoice &&
                                selectedInvoice.invoiceFile && (
                                  <div>
                                    <span>
                                      Selected file:{" "}
                                      {selectedInvoice.invoiceFile}
                                    </span>
                                    <a
                                      href={`/uploads/${selectedInvoice.invoiceFile}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View
                                    </a>
                                  </div>
                                )} */}
                            {/* <span id="fileNameDisplay">
                                {selectedInvoice &&
                                  "Selected file: " +
                                    selectedInvoice.invoiceFile}
                              </span> */}
                            <div className="invoice-form-input-field-extra-holder">
                              {errors?.invoiceFile && (
                                <p className="errorMsg">
                                  {errors?.invoiceFile.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="einvoice-values-field">
                          <label>Upload support documents</label>
                          <div className="einvoice-values-input-field">
                            <input
                              type="file"
                              placeholder="Upload"
                              accept="application/pdf"
                              {...register("supportFile", {
                                // required: "*required",
                              })}
                            />
                            {/* {selectedInvoice &&
                                selectedInvoice.supportFile && (
                                  <div>
                                    <span>
                                      Selected file:{" "}
                                      {selectedInvoice.supportFile}
                                    </span>
                                    <a
                                      href={`/uploads/${selectedInvoice.supportFile}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View
                                    </a>
                                  </div>
                                )} */}
                            <div className="invoice-form-input-field-extra-holder">
                              {errors?.supportFile && (
                                <p className="errorMsg">
                                  {errors?.supportFile.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="einvoice-values-field">
                          <label> E-invoice Available</label>
                          <div className="einvoice-values-input-field">
                            <select {...register("eway_bill_available_or_not")}>
                              <option value="">Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            {errors?.eway_bill_available_or_not && (
                              <p className="errorMsg">
                                {errors?.eway_bill_available_or_not.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="einvoice-values-field">
                          <label> E-way Bill no.(if applicable)</label>
                          <div className="einvoice-values-input-field">
                            <input type="text" placeholder="E-way Bill no." />
                          </div>
                        </div>
                        <div className="einvoice-values-field">
                          <label>QR code with IRN no.</label>
                          <div className="einvoice-values-input-field">
                            <input
                              type="text"
                              placeholder="QR code with IRN No"
                              {...register("qr_code_with_irn_no")}
                            />
                          </div>
                        </div>
                        <div className="einvoice-values-field">
                          <label>
                            Invoice upload status in GSTN portal- GSTR-1
                          </label>
                          <div className="einvoice-values-input-field">
                            <input
                              type="text"
                              placeholder="Invoice Upload Status in GSTN Portal"
                              {...register("irp_invoice_upload_status")}
                            />
                          </div>
                        </div>
                        <div className="einvoice-values-field">
                          <label> Whether Imports(yes/no)</label>
                          <div className="einvoice-values-input-field">
                            <select
                              {...register("whether_imports", {
                                // required: "*required",
                              })}
                              onChange={(e) => setIsImport(e.target.value)}
                            >
                              <option value="" selected>
                                Select
                              </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            {errors?.whether_imports && (
                              <p className="errorMsg">
                                {errors?.whether_imports.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="einvoice-values-field">
                          <label>Import Date</label>
                          <div className="einvoice-values-input-field">
                            <input
                              type="date"
                              {...register("date_for_import_of_goods")}
                              disabled={isImport === "No" ? 1 : 0}
                            />
                          </div>
                        </div>
                        <div className="einvoice-values-field">
                          <label>Bill Of Entry No</label>
                          <div className="einvoice-values-input-field">
                            <input
                              type="text"
                              placeholder="Bill Of Entry No"
                              {...register("bill_of_entryno")}
                              disabled={isImport === "No" ? 1 : 0}
                            />
                          </div>
                        </div>

                        <div className="einvoice-values-field">
                          {/* <label>Contact Person Email ID</label>
                            <input
                              type="email"
                              placeholder="Contact Person Email"
                              {...register("contact_person_email_id", {
                                required: "*required",
                              })}></input>
                            <div className="invoice-form-input-field-extra-holder">
                              {errors?.contact_person_email_id && (
                                <p className="errorMsg">
                                  {errors?.contact_person_email_id.message}
                                </p>
                              )}
                            </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <div className="btn-box">
              <input
                className="button"
                disabled={current === 0}
                onClick={handleBack}
                value="Back"
                type="button"
              />

              {/* <input
                  className="button"
                  disabled={current <= 1}
                  onClick={handleDraft}
                  value="Draft"
                  // type="submit"
                  type="button"
                  //disabled={true}
                /> */}

              <input
                className="button"
                // variant="contained"
                // color="primary"
                // disabled={current === 0}
                value={current === steps.length - 1 ? "Submit" : "Next"}
                onClick={(e) => {
                  //console.log("Submit Button clicked");
                  handleSubmit(handleNext)(e);
                }}
                disabled={!isValidInvoiceValue}
                type="button"
              />
            </div>
          </form>
        </>
        {/* FORM END*/}
      </div>
    </>
  );
};
export default InvoiceForm;
